<template>
  <div>
    <b-container fluid>
      <b-row>
          <div class="router-links">
            <router-link :to="{path: `/${lang}/siteinfo/pvstringfailureanalysis/powerfee`, query: routeQuery}">
            <div class="vol-income" :class="{'active': activeOrder === 0}" @click="selectAnalysis(0)">{{ $t("pvstringfailureanalysis.liveInfo") }}</div>
            </router-link>
            <router-link :to="{path: `/${lang}/siteinfo/pvstringfailureanalysis/groupconfig`, query: routeQuery}">
            <div class="transform" :class="{'active': activeOrder === 1}" @click="selectAnalysis(1)">{{ $t("pvstringfailureanalysis.groupConfiguration") }}</div>
            </router-link>
            <router-link :to="{path: `/${lang}/siteinfo/pvstringfailureanalysis/circlechart`, query: routeQuery}">
            <div class="circle-chart" :class="{'active': activeOrder === 2}" @click="selectAnalysis(2)">{{ $t("pvstringfailureanalysis.circleChart") }}</div>
            </router-link>
            <router-link :to="{path: `/${lang}/siteinfo/pvstringfailureanalysis/queryhistorydata`, query: routeQuery}">
            <div class="average-shine" :class="{'active': activeOrder === 3}" @click="selectAnalysis(3)">{{ $t("pvstringfailureanalysis.historyData") }}</div>
            </router-link>
          </div>
      </b-row>
      <router-view></router-view>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'pvstringfailureanalysis',
  data () {
    return {
      activeOrder: 0,
      routeQuery: ''
    }
  },
  methods: {
    selectAnalysis (idx) {
      this.activeOrder = idx
    }
  },
  computed: {
    lang () {
      return this.$route.params.lang
    }
  },
  created () {
    this.routeQuery = this.$route.query
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/PvStringFailureAnalysis.scss'
</style>
